@import url("https://fonts.googleapis.com/css?family=PT+Sans&display=swap");
@font-face {
  font-family: "indira_kregular";
  src: url("../../assets/fonts/indira_k-webfont.woff2") format("woff2"),
    url("../../assets/fonts/indira_k-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.py-5 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.container {
  max-width: 1292px !important;
}

body {
  height: 100%;
  min-width: 320px;
}
.no-border {
  border-radius: 0 !important;
}

.mobile {
  background: none !important;
}

.body-text-copyright {
  color: #a0a0a0;
  font-size: 12.9px;
  line-height: 12.1px;
  padding: 0 34px 26.4px;
  letter-spacing: -0.5px;
}

.home {
  min-height: 100%;
  min-width: 100%;
}

@media only screen and (max-width: 768px) {
  .App {
    min-height: 400px;
  }
  .container {
    padding: 0 !important;
  }

  .img-logo {
    max-width: 230pt !important;
  }

  .row {
    width: 97% !important;
  }
}

@media only screen and (max-width: 505px) {
  .row {
    width: 97% !important;
  }

  .helvar-merca-profi {
    padding: 12.5px 20px;
  }
}
